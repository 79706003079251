import DoneIcon from "@mui/icons-material/Done";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

export const suggestedMsgs = [
  {
    id: 1,
    question: "Are you looking to refine your chatbot's accuracy?",
    answer:
      "Absolutely! Share the questions you want to use, and we'll work on improving the accuracy of your chatbot's responses.",
    action: "more",
    clickable: true,
  },
  {
    id: 2,
    question: "Do you want to train your model?",
    answer:
      "Sure, please enter the questions you'd like to use to train your model.",
    action: "train-model",
    clickable: true,
  },
  {
    id: 3,
    question: "Are you ready to enhance your chatbot's capabilities?",
    answer:
      "Absolutely! Let's get started. Input the questions you want to use for training.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 4,
    question: "Are you ready to improve your chatbot's understanding?",
    answer:
      "Certainly! Provide the questions you want to use, and we'll enhance the model's comprehension.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 5,
    question: "Ready to fine-tune your chatbot's responses?",
    answer:
      "Great! Enter the questions you have in mind, and we'll optimize the model's answers.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 6,
    question: "Interested in refining your chatbot's knowledge?",
    answer:
      "Excellent! Share the questions you'd like to use for training, and we'll work on improving the model.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 7,
    question: "Would you like to customize your chatbot's interactions?",
    answer:
      "Certainly! Provide the questions you want to use, and we'll tailor the responses to your specifications.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 8,
    question: "Ready to make your chatbot more intelligent?",
    answer:
      "Great! Input the questions you want to use for training, and we'll enhance the model's intelligence.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 9,
    question:
      "Are you interested in refining your chatbot's conversational skills?",
    answer:
      "Absolutely! Share the questions you have in mind, and we'll work on improving the chatbot's ability to engage in conversations.",
    action: "purpose",
    clickable: true,
  },

  {
    id: 10,
    question: "Is it time to make your chatbot more user-friendly?",
    answer:
      "Certainly! Provide the questions you want to use, and we'll focus on making the chatbot's interactions more user-friendly.",
    action: "purpose",
    clickable: true,
  },
  {
    id: 11,
    question: "Ready to optimize your chatbot's performance?",
    answer:
      "Great! Enter the questions you'd like to use for training, and we'll work on optimizing the chatbot's overall performance.",
    action: "purpose",
    clickable: true,
  },
];

export const actionMsgs = [
  {
    id: 1,
    question: "Yes",
    answer:
      "Sure, please enter the questions you'd like to use to train your model.",
    icon: <DoneIcon fontSize="small" />,
    action: "more",
  },
  {
    id: 2,
    question: "No",
    answer:
      "Ok, Please tell the name of file for your model. eg. training.txt, output.txt",
    icon: <DoNotDisturbAltIcon fontSize="small" />,
    action: "finished",
  },
];

export const responseMsgs = [
  {
    id: 1,
    text: "Awesome! Provide additional related questions to enhance the model's training for improved efficiency.",
  },
  {
    id: 2,
    text: "Fantastic! Share more relevant queries to optimize the model's training and boost its effectiveness.",
  },
  {
    id: 3,
    text: "Excellent! Offering more related questions will contribute to refining the model's efficiency during training.",
  },
  {
    id: 4,
    text: "Outstanding! For better efficiency, supply additional queries that are closely related to the task at hand.",
  },
  {
    id: 5,
    text: "Superb! Give us more queries related to the topic to enhance the model's training and overall efficiency.",
  },
  {
    id: 6,
    text: "Terrific! Contributing additional related questions will play a key role in maximizing the model's efficiency.",
  },
  {
    id: 7,
    text: "Impressive! To achieve better effectiveness, furnish additional queries closely tied to the model's training objectives.",
  },
];
