import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import CustomHeading from "../../../components/CustomHeading";
import CustomLabel from "../../../components/CustomLabel";
import CustomTextInput from "../../../components/CustomTextInput";
import CustomSelectInput from "../../../components/CustomSelectInput";

import { useState } from "react";
import { api } from "../../../services/api";
import { useEffect } from "react";
import LoaderComponent from "../../../components/LoaderComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
export default function AddNewBot(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleSubmit, handleClose, loading, setLoading } = props;
  const [industries, setIndustries] = useState();
  const [aiModels, setAiModels] = useState();
  const [formData, setFormData] = useState({
    bot_name: "",
    industry_id: "",
    aimodel_id: "",
    custom_prompt: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const closeModal = () => {
    setLoading(false);
    handleClose();
  };

  const fetchData = async () => {
    const getModelsAndIndustries = async (endpoint) => {
      let response = await api.get(`${endpoint}/`);
      return response?.data;
    };

    const apiEndpoints = ["industries", "aimodels"];

    const promises = await Promise.all(
      apiEndpoints?.map((endpoint) => getModelsAndIndustries(endpoint))
    );
    return promises;
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let data = await fetchData();
      setFormData({
        ...formData,
        industry_id: data[0][0]?.id,
        aimodel_id: data[1][0]?.id,
      });
      setIndustries(data[0]);
      setAiModels(data[1]);
      setLoading(false);
    };
    getData();
  }, []);

  const industriesOptions = industries?.map((item, index) => {
    return {
      id: index,
      value: item.id,
      label: item.industry_name,
    };
  });

  const modelOptions = aiModels?.map((item, index) => {
    return {
      id: index,
      value: item.id,
      label: item.aimodel_name,
    };
  });

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CustomHeading text="Add new bot" />
            <form onSubmit={(e) => handleSubmit(e, formData)}>
              <div className="mt-3">
                <CustomLabel label="Bot Name" htmlFor="bot_name" />
                <CustomTextInput
                  id="bot_name"
                  name="bot_name"
                  inputClassNames="mt-2"
                  placeholder="Enter bot name"
                  onChange={handleChange}
                  value={formData.bot_name}
                />
              </div>

              <div className="mt-3">
                <CustomLabel label=" Select Industry" />
                <CustomSelectInput
                  name="industry_id"
                  onChange={handleChange}
                  value={formData.industry_id}
                  options={industriesOptions}
                />
              </div>

              <div className="mt-3">
                <CustomLabel label=" Select Model" />
                <CustomSelectInput
                  name="aimodel_id"
                  onChange={handleChange}
                  value={formData.aimodel_id}
                  options={modelOptions}
                />
              </div>

              {/* Custom Prompt */}
              {/* <div className="mt-3">
                <CustomLabel label="Custom Prompt" htmlFor="custom_prompt" />
                <CustomTextInput
                  id="custom_prompt"
                  name="custom_prompt"
                  inputClassNames="mt-2"
                  placeholder="Enter your custom prompt"
                  onChange={handleChange}
                  value={formData.custom_prompt}
                />
              </div> */}

              <div className="justify-start flex  items-center mt-5">
                <Button
                  type="submit"
                  variant="contained"
                  className="!ml-3 prim_btn"
                  size="small"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={closeModal}
                  className="!ml-3 empty_btn"
                >
                  Cancel
                </Button>
              </div>
            </form>

            <LoaderComponent loading={loading} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
