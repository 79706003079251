import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, CircularProgress } from "@mui/material";

const DeleteModal = (props) => {
  const { open, handleCloseDelete, handleDelete, title, bodyText, loading } =
    props;
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="!text-black font-bold"
          >
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="empty_btn" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button className="prim_btn" onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            opacity: 0.4,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteModal;
