import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import file_icon from "../../assets/images/file_icon.png";

const useStyles = makeStyles(() => ({
  dropZone: {
    border: "2px dashed #d2d6dc",
    borderRadius: "4px",
    padding: "2rem",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f7fafc",
    },
  },
  fileInput: {
    // display: "none",
  },
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "100%",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: "100%",
});
const FileUpload = ({
  selectedFile,
  setSelectedFile,
  disabled,
  onFocus = () => {},
  setUrlsDisabled,
}) => {
  const classes = useStyles();
  // const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    onFocus();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    if (!disabled) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const onDelete = async () => {
    setSelectedFile(null);
    setUrlsDisabled(false);
  };

  const onClick = () => {};
  return (
    <div
      className={classes.dropZone}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {selectedFile ? (
        <div className="flex justify-between items-center">
          <p>{selectedFile.name}</p>
          <DeleteIcon
            onClick={onDelete}
            className="cursor-pointer"
            style={{ fontSize: 20, color: "var(--primary-color)" }}
          />
        </div>
      ) : (
        <>
          <div className="center-div">
            <img src={file_icon} width={16} height={16} alt="file_icon" />
            <p className="ml-2 text-sm">Drop your file here </p>
          </div>
          <Button
            role={undefined}
            size="large"
            component="label"
            variant="outlined"
            className="outlined_btn !mt-3"
          >
            Choose File{" "}
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
        </>
      )}
    </div>
  );
};

export default FileUpload;
