import React from "react";

const CustomSelectInput = ({
  value,
  onChange,
  id,
  inputClassNames = "",
  name,
  defautlValue,
  icon,
  placeholder,
  options,
}) => {
  return (
    <>
      <div className="mt-2">
        <select
          placeholder={placeholder}
          id={id}
          name={name}
          className={`block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${inputClassNames}`}
          value={value}
          onChange={onChange}
        >
          {options?.map((item) => (
            <option key={id} value={item.value}>
              {item?.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default CustomSelectInput;
