import { api } from "../../services/api";
import { conv_api } from "../../services/conv_api";
import { storeInLocalStorage } from "../../services/localStorage";
import { generateRandomString } from "../../utils/helper";

export const getUser = async () => {
  try {
    let response = await api.get(`/users/me`);
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const updateUser = async (data) => {
  try {
    let response = await api.put(`/users/`, data);
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const getBot = async (botId) => {
  try {
    let response = await api.get(`/bots/${botId}`);
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const getUserProjects = async () => {
  try {
    let response = await api.get(`projects/`);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createNewConversation = async (obj) => {
  try {
    let visitor_id = generateRandomString(8);
    let response = await conv_api.post(
      `new_conversation?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&visitor_id=${visitor_id}`,
      {}
    );
    storeInLocalStorage("session_id", response?.data?.session_id);
    return response?.data?.session_id;
  } catch (error) {
    console.log("error", error);
  }
};
