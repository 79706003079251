import { api } from "../../../../services/api";

export const createProject = async (data) => {
  try {
    let response = await api.post(`projects/`, data);
    return response?.data;
  } catch (error) {
    console.log("Error", error?.response?.data?.detail);
  }
};

export const createBot = async (data) => {
  try {
    let response = await api.post(`bots/`, data);
    return response?.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const updateBot = async (data) => {
  try {
    let response = await api.put(`bots/${data?.id}`, data);
    return response?.data;
  } catch (error) {
    console.log("Error", error.message);
  }
};
