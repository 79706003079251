import React, { useEffect, useState } from "react";
import CustomWidget from "../../components/CustomWidget";
import { useSelector } from "react-redux";
import { api } from "../../services/api";

const EnforcementLearning = () => {
  const [sessionId, setSessionId] = useState();
  const state = useSelector((state) => state?.auth);
  const [widgetAppereance, setWidgetAppearance] = useState({
    profile_pic: null,
    initial_message: "Hi, how can I help you?",
    initial_suggested_prompt: "Great! how are you?",
    theme: "light",
    botMsgBackgroundColor: "#707070",
    background_color: "#F3F4F6",
    suggestedMsgBackgroundColor: "#F3F4F6",
    timeTextColor: "#6F7E94",
  });

  const getWidgetSettings = async (projectId, botId) => {
    try {
      const response = await api.get(
        `customization/?bot_id=${botId}&project_id=${projectId}`
      );
      setWidgetAppearance({ ...widgetAppereance, ...response?.data });
    } catch (error) {
      console.log("Error", error);
    } finally {
    }
  };

  useEffect(() => {
    getWidgetSettings(state?.project_id, state?.bot_id);
  }, [state?.bot_id]);
  return (
    <>
      <CustomWidget
        setSessionId={setSessionId}
        sessionId={null}
        widgetAppereance={widgetAppereance}
        setWidgetAppearance={setWidgetAppearance}
        playground="true"
        type="train"
      />
    </>
  );
};

export default EnforcementLearning;
