import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomLabel from "../../components/CustomLabel";
import CustomTextInput from "../../components/CustomTextInput";
import { Switch } from "@mui/material";
import CustomSelectInput from "../../components/CustomSelectInput";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LoaderComponent from "../../components/LoaderComponent";
import { getUser, updateUser } from "../../common/APIs";
import { getFormValues } from "../../utils/helper";
import { useSnackbar } from "notistack";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Profile = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState("Users");
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [user, setUser] = useState();

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFiles = (e) => {
    const file = e.target.files[0]; // Get the first selected file

    if (file && file instanceof Blob) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        console.log(base64String);
        setImgUrl(base64String);
      };

      reader.readAsDataURL(file); // Convert the file to a Base64 string
    } else {
      console.error("Selected file is not a valid Blob.");
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const getData = async () => {
    try {
      setLoading(true);
      let res = await getUser();
      setUser(res);
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const values = await getFormValues(event);
    setUser({
      ...user,
      ...values,
    });
    let formData = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      picture: "string",
      phone_no: user?.phone_no,
    };

    try {
      let resp = await updateUser(formData);
      enqueueSnackbar("Successfully updated user!", { variant: "success" });
    } catch (error) {
      console.log("Error", error);
      enqueueSnackbar("Something went wrong. Please Try again!", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  return (
    <div className="">
      <section className="">
        <div className="bg-white px-5 py-8   border border-gray-300 rounded-lg">
          <h2 className="font-bold text-xl">Settings</h2>
          <div className="flex justify-start mt-4 mb-6">
            <div className="flex bg-gray-100 rounded-full border border-gray-100">
              {["Users", "Integration", "Notification", "API"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => openTab(tab)}
                  className={`px-6 py-2 text-sm font-medium rounded-full ${
                    activeTab === tab ? "bg-white text-black" : "text-gray-500"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>

          <div className={activeTab === "Users" ? "block" : "hidden"}>
            <h2 className="text-purple-600 text-xl font-medium">My Profile</h2>
            <form onSubmit={onFormSubmit}>
              <div className="flex items-center my-4">
                {/* <div>
                  <Avatar
                    sx={{ width: 100, height: 100 }}
                    className="bg-white p-0.5 mr-5"
                    src={imgUrl}
                  />
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFiles}
                    hidden
                  />
                  <p
                    onClick={triggerFileInput}
                    className="text-gray-500 text-sm mt-2  cursor-pointer"
                  >
                    Edit Avatar
                  </p>
                </div> */}

                <input type="file" onChange={handleFiles} hidden />

                <div className="flex-1">
                  <CustomLabel label="First Name" htmlFor="first_name" />
                  <CustomTextInput
                    name="first_name"
                    value={user && user?.first_name}
                    onChange={handleInputChange}
                    placeholder="Enter your First Name"
                  />
                </div>
                <div className="flex-1 ml-3">
                  <CustomLabel label="Last Name" htmlFor="last_name" />
                  <CustomTextInput
                    name="last_name"
                    value={user && user?.last_name}
                    onChange={handleInputChange}
                    placeholder="Enter your Last Name"
                  />
                </div>
              </div>
              <div className="mt-4">
                <CustomLabel label="Email" htmlFor="email" />
                <CustomTextInput
                  name="email"
                  value={user && user?.email}
                  onChange={handleInputChange}
                  placeholder="Enter your Email"
                />
              </div>

              <div className="mt-6">
                <CustomButton type="subnit" title="  Save Settings" />
              </div>
            </form>
          </div>

          <div className={activeTab === "Integration" ? "block" : "hidden"}>
            <h2 className="text-purple-600 text-xl font-medium">Integration</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
              {[
                "zapier-img",
                "whataps-img",
                "zendesk-img",
                "calendly-img",
                "discord-img",
                "team-img",
                "slack-img",
                "hubspot",
              ].map((img, index) => (
                <div
                  key={index}
                  className="bg-gray-100 border border-gray-300 rounded-md p-4"
                >
                  <a href="#">
                    <img src={`assets/images/${img}.png`} alt={img} />
                  </a>
                  <p className="text-gray-500 text-sm mt-2">
                    Connect your Bot nest account with Zendesk to automatically
                    create tickets from Bot nest.
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className={activeTab === "Notification" ? "block" : "hidden"}>
            <h2 className="text-purple-600 text-lg font-medium">
              Email Notification
            </h2>
            <div className="flex items-center my-4">
              <Switch />
              <label className="ml-2 mx-3 whitespace-nowrap">
                Send email notifications about new chat summaries
              </label>
              <CustomSelectInput
                placeholder="Write suggested message"
                options={[
                  { id: 1, value: "daily", label: "Daily" },
                  { id: 2, value: "weekly", label: "Weekly" },
                  { id: 3, value: "monthly", label: "Monthly" },
                  { id: 4, value: "quarterly", label: "Quarterly" },
                  { id: 5, value: "yearly", label: "Yearly" },
                ]}
              />
            </div>

            <h2 className="text-purple-600 text-lg font-medium mt-6">
              Live Chat Notifications
            </h2>
            <div className="flex items-center my-4">
              <Switch />
              <label className="ml-2 mx-3 whitespace-nowrap	">
                Play ringing sound when there is a new live chat
              </label>
              <CustomSelectInput
                placeholder="Write suggested message"
                options={[
                  { id: 1, value: "daily", label: "Daily" },
                  { id: 2, value: "weekly", label: "Weekly" },
                  { id: 3, value: "monthly", label: "Monthly" },
                  { id: 4, value: "quarterly", label: "Quarterly" },
                  { id: 5, value: "yearly", label: "Yearly" },
                ]}
              />
            </div>

            <div className="flex items-center my-4">
              <Switch />
              <label className="ml-2 mx-3">
                Enable desktop notifications for new live chat messages
              </label>
            </div>
          </div>

          <div className={activeTab === "API" ? "block" : "hidden"}>
            <h2 className="text-purple-600 text-lg font-medium">API Keys</h2>
            <p className="text-sm mt-2">
              Your secret API keys are listed below. Do not share your API key
              with others, or expose it in the browser or other client-side
              code. If you find that your API key might have been leaked, you
              may delete it to revoke the API key.
            </p>
            <h2 className="text-purple-600 text-lg font-medium mt-4">
              View API Documentation
            </h2>
            <p className="text-gray-500 text-xs">Last updated: 19 Feb 2024</p>
            <div className="flex mt-4">
              <h4 className="flex-1 text-sm font-medium">KEY</h4>
              <h4 className="flex-1 text-sm font-medium">CREATED</h4>
              <h4 className="flex-1 text-sm font-medium">ACTIONS</h4>
            </div>
            <div className="mt-6">
              <CustomButton title=" Generate API Keys" />
            </div>
          </div>
        </div>
      </section>
      <LoaderComponent loading={loading} />
    </div>
  );
};

export default Profile;
