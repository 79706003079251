import React, { useEffect, useRef, useState } from "react";
import WidgetHeader from "./components/WidgetHeader";
import SendBox from "./components/SendBox";
import "./index.css";
import CustomizeMessageRowMessagesRow from "./components/CustomizeMessageRow";
import MessagesRow from "./components/MessageRow";
import { api } from "../../services/api";
import {
  getItemFromLocalStorage,
  storeInLocalStorage,
} from "../../services/localStorage";
import { conv_api } from "../../services/conv_api";
import { CHATBOT_MODELLING_API_URL } from "../../constants/URLS";
import { generateRandomString, getRandomObjects } from "../../utils/helper";
import { responseMsgs, suggestedMsgs } from "../../data/suggestedMsgs.js";
import { Backdrop, Chip, CircularProgress, Stack } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useSnackbar } from "notistack";
import { createNewConversation } from "../../common/APIs/index.js";
import { useSelector } from "react-redux";

const CustomWidget = ({
  sessionId,
  widgetAppereance,
  setWidgetAppearance,
  playground = "false",
  setSessionId,
  type,
  fromConv = false,
}) => {
  const state = useSelector((state) => state?.auth);
  const [loading, setLoading] = useState(false);
  const [updatedSessionId, setUpdatedSessionId] = useState();

  const [messages, setMessages] = useState([]);
  const [trainingMessages, setTrainingMessages] = useState([]);
  const [blur, setBlur] = useState(true);
  const [chatEnded, setChatEnded] = useState(false);
  const [visitorMsgs, setVisitorMsgs] = useState([]);
  const [botMsgs, setBotMsgs] = useState([]);
  const [end, setEnd] = useState(false);

  const [user, setUser] = useState();
  const containerRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const getUser = async () => {
    try {
      let response = await api.get("users/me");
      setUser(response?.data);
      return response?.data;
    } catch (error) {
      return "error";
    }
  };
  const getSingleConversation = async (sessionId) => {
    setLoading(true);
    let user = await getUser();
    let user_id = user?.id;
    let project_id = state?.project_id;
    let bot_id = state?.bot_id;
    try {
      let response = await conv_api.post(
        `get_conversation?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}&session_id=${sessionId}`
      );
      if (response?.data) {
        let questions =
          response?.data?.questions?.map((msgs) => {
            return { ...msgs, isVisitor: true };
          }) ?? [];
        let answers =
          response?.data?.answers?.map((msgs) => {
            return { ...msgs };
          }) ?? [];
        const mergedMessages = questions?.concat(answers);
        mergedMessages?.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        const arrayOfStrings = questions?.map((obj) => obj.text);
        setVisitorMsgs(arrayOfStrings);
        if (messages?.length === 1) {
          setMessages(messages);
        } else {
          setMessages(mergedMessages);
        }
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };
  const appendString = (string) => {
    setBotMsgs((prevBotMsgs) => [...prevBotMsgs, string]);
  };

  const scrollToBottom = () => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    getUser();
  }, []);

  const createConv = async (obj) => {
    try {
      let response = await conv_api.post(
        `new_conversation?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&visitor_id=${obj?.visitor_id}`,
        {}
      );
      storeInLocalStorage("session_id", response?.data?.session_id);
      let updatedMsg = {
        ...obj,
        session_id: response?.data?.session_id,
      };
      setSessionId(response?.data?.session_id);
      postMessage(updatedMsg);
    } catch (error) {
      console.log("error", error);
    }
  };
  const postMessage = async (msgObj) => {
    try {
      setEnd(true);

      let url = `${CHATBOT_MODELLING_API_URL}query?user_id=${msgObj?.user_id}&project_id=${msgObj?.project_id}&question=${msgObj?.question}&bot_id=${msgObj?.bot_id}&session_id=${msgObj?.session_id}`;

      var response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var reader = response.body.getReader();
      var decoder = new TextDecoder("utf-8");
      async function processResult() {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          let token = decoder.decode(value);

          if (
            token.endsWith(".") ||
            token.endsWith("!") ||
            token.endsWith("?")
          ) {
            appendString(token);
          } else {
            appendString(token);
          }
        }
      }

      await processResult();
      setEnd(false);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const sendMessage = async (msg) => {
    let allMsgsArray = [...visitorMsgs];
    allMsgsArray.push(msg);
    scrollToBottom();
    const newObject = {
      id: messages.length + 1,
      text: msg,
      timestamp: new Date(),
      isVisitor: true,
    };

    setVisitorMsgs([...visitorMsgs, msg]);
    setMessages([...messages, newObject]);
    let project_id = state?.project_id;
    let bot_id = state?.bot_id;

    let session_id = sessionId;
    let user_id = user?.id;

    let obj = {
      bot_id: bot_id,
      session_id: session_id,
      user_id: user_id,
      project_id: project_id,
      question: msg,
      visitor_id: generateRandomString(8),
    };

    if (session_id) {
      postMessage(obj);
    } else {
      createConv(obj);
    }
  };

  const convertArrayToTxtFile = (array) => {
    const textContent = array.join("\n");
    const blob = new Blob([textContent], { type: "text/plain" });
    return blob;
  };
  const trainApi = async (file, filename) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("file_one", file, filename); // Adjust the key and filename as needed

      let project_id = state?.project_id;
      let bot_id = state?.bot_id;

      let user_id = user?.id;
      let response = await conv_api.post(
        `train_model?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data) {
        let obj = {
          user_id: user_id,
          project_id: project_id,
          bot_id: bot_id,
        };
        let newSessionId = await createNewConversation(obj);
        // setSessionId(newSessionId);
        setChatEnded(true);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const endTraining = async (filename) => {
    const file = convertArrayToTxtFile(visitorMsgs);
    try {
      await trainApi(file, filename);
      enqueueSnackbar("Document deleted successfully!", {
        variant: "success",
      });
      console.log("File sent successfully");
    } catch (error) {
      console.error("Error sending file to API", error);
    }
  };

  useEffect(() => {
    if (sessionId) {
      getSingleConversation(sessionId);
    } else {
      setMessages([]);
      setSessionId(null);
    }
  }, [sessionId]);

  const sendTrainMesseges = async (msg) => {
    let randomMsg = getRandomObjects(responseMsgs, 1);
    var splitString = randomMsg[0]?.text?.split("");
    let allMsgsArray = [...visitorMsgs];
    allMsgsArray.push(msg);
    setVisitorMsgs([...visitorMsgs, msg]);

    setTrainingMessages((prevMessages) => [
      ...prevMessages,
      {
        id: messages.length + 1,
        text: msg,
        timestamp: new Date(),
        isVisitor: true,
      },
    ]);

    setEnd(true);

    for (let i = 0; i < splitString.length; i++) {
      const char = splitString[i];
      await new Promise((resolve) => setTimeout(resolve, 10));
      appendString(char);
    }

    setEnd(false);
  };

  const handleClickChipAction = async () => {
    setBlur(false);
    setEnd(true);
    let randomMsg = getRandomObjects(responseMsgs, 1);
    var splitString = randomMsg[0]?.text?.split("");
    for (let i = 0; i < splitString.length; i++) {
      const char = splitString[i];
      await new Promise((resolve) => setTimeout(resolve, 10));
      appendString(char);
      scrollToBottom();
    }

    setEnd(false);
  };

  return (
    <>
      <div className="relative pb-1 h-[100%]">
        {type === "train" && blur && (
          <div className="absolute flex flex-col items-center justify-center w-[100%] h-[100%] z-50">
            <Stack
              direction="row"
              spacing={1}
              justifyContent="right"
              className="!mb-5"
              flexWrap="wrap"
              maxWidth="70%"
            >
              <Chip
                style={{ zIndex: "999999" }}
                label={getRandomObjects(suggestedMsgs, 1)[0]?.question}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="right"
              className="!mb-5 !ml-3"
              flexWrap="wrap"
              maxWidth="70%"
            >
              <Chip
                style={{ zIndex: "999999" }}
                label="Yes"
                onClick={handleClickChipAction}
                clickable={true}
                icon={<DoneIcon fontSize="small" />}
              />
            </Stack>
          </div>
        )}
        <div
          className="flex items-center flex-row justify-center"
          style={{
            height: "100%",

            filter: `${blur && type === "train" ? "blur(6px)" : "blur(0px)"}`,
          }}
        >
          <br />
          <div
            style={{
              width: "100%",
              borderRadius: "0.5rem",
              boxShadow:
                "0px 0px .9310142993927002px 0px rgba(0, 0, 0, .17), 0px 0px 3.1270833015441895px 0px rgba(0, 0, 0, .08), 0px 7px 14px 0px rgba(0, 0, 0, .05)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <WidgetHeader
              widgetAppereance={widgetAppereance}
              setWidgetAppearance={setWidgetAppearance}
              modelTrainApi={endTraining}
            />
            {playground === "false" ? (
              <div className="msgs_container p-4 ">
                <CustomizeMessageRowMessagesRow
                  widgetAppereance={widgetAppereance}
                  setWidgetAppearance={setWidgetAppearance}
                />
              </div>
            ) : (
              <>
                <div className="msgs_container p-4 relative">
                  <MessagesRow
                    widgetAppereance={widgetAppereance}
                    setWidgetAppearance={setWidgetAppearance}
                    messages={type === "train" ? trainingMessages : messages}
                    botMsgs={botMsgs}
                    end={end}
                    setBotMsgs={setBotMsgs}
                    scrollToBottom={scrollToBottom}
                    setMessages={
                      type === "train" ? setTrainingMessages : setMessages
                    }
                    endTraining={endTraining}
                    chatEnded={chatEnded}
                    conversationType={type}
                    setEnd={setEnd}
                    appendString={appendString}
                    loading={loading}
                  />
                  <div ref={containerRef}></div>
                </div>
              </>
            )}
            {!chatEnded && !fromConv && (
              <SendBox
                user={user}
                sessionId={sessionId}
                setEnd={setEnd}
                appendString={appendString}
                scrollToBottom={scrollToBottom}
                sendMessage={sendMessage}
                msgProcessing={end}
                conversationType={type}
                trainingMessages={sendTrainMesseges}
                setMessages={
                  type === "train" ? setTrainingMessages : setMessages
                }
                messages={type === "train" ? trainingMessages : messages}
              />
            )}
          </div>
        </div>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            opacity: 0.4,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default CustomWidget;
