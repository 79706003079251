import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { createNewConversation, getUser } from "../../../common/APIs";
import { countries } from "../../../constants/countries";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomHeading from "../../../components/CustomHeading";
import { makeStyles } from "@mui/styles";
import CustomTextInput from "../../../components/CustomTextInput";
import CustomSelectInput from "../../../components/CustomSelectInput";
import CustomLabel from "../../../components/CustomLabel";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  disabledSwitch: {
    "& .MuiSwitch-thumb": {
      backgroundColor: "gray !important", // Customize the color of the thumb when switch is disabled
    },
    "& .MuiSwitch-track": {
      backgroundColor: "gray !important", // Customize the color of the track when switch is disabled
    },
  },
}));
const BotSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const state = useSelector((state) => state?.auth);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    max_credit_spend_on_chatgpt: "",
    max_message_user_ask_limit: "",
    interval: "",
    no_of_request_in_interval: "",
    credit_low_email_trigger: false,
    is_memory: false,
    is_prompt: false,
    domain_restriction: false,
    domain_list: "",
    platform_restriction: false,
    platform_mobile: false,
    platform_desktop: false,
    country_restriction: false,
    country_list: [],
    custom_prompt: "",
  });

  useEffect(() => {
    const getBot = async () => {
      try {
        setLoading(true);
        let response = await api.get(`bots/${state?.bot_id}`);
        let bot = response?.data;
        setFormData({
          ...formData,
          max_credit_spend_on_chatgpt: bot?.max_credit_spend_on_chatgpt || "",
          max_message_user_ask_limit: bot?.max_message_user_ask_limit || "",
          interval: bot?.interval || "",
          no_of_request_in_interval: bot?.no_of_request_in_interval || "",
          credit_low_email_trigger: bot?.credit_low_email_trigger || false,
          is_memory: bot?.is_memory || false,
          is_prompt: bot?.is_prompt || false,
          domain_restriction: bot?.domain_restriction || false,
          domain_list: bot?.domain_list?.join(",") || "",
          platform_restriction: bot?.platform_restriction || false,
          platform_mobile: bot?.platform_mobile || false,
          platform_desktop: bot?.platform_desktop || false,
          country_restriction: bot?.country_restriction || false,
          country_list: bot?.country_list || [],
          custom_prompt: bot?.custom_prompt || "",
        });
      } catch (error) {
        console.log("Error", error);
        enqueueSnackbar("Not updated. Something went wrong!", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    getBot();
  }, [state?.bot_id]);

  const updateBotSettings = async (values) => {
    try {
      let response = await api.post(`bots/settings/${state?.bot_id}`, values);
      enqueueSnackbar("Successfully updated Bot Settings!", {
        variant: "success",
      });
    } catch (error) {
      error?.response?.data?.detail?.map((detail, index) => {
        enqueueSnackbar(detail?.msg, {
          variant: "error",
        });
      });
    }
  };
  const onFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const FormValues = {
      ...formData,
      domain_list: formData?.domain_list.split(","),
    };

    let user = await getUser();
    let bot_id = state?.bot_id;
    let project_id = state?.project_id;

    let obj = {
      user_id: user?.id,
      bot_id: bot_id,
      project_id: project_id,
    };

    await updateBotSettings(FormValues);
    if (FormValues?.is_memory || FormValues?.is_prompt) {
      await createNewConversation(obj);
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeCountries = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      country_list: typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <>
      <div className=" mt-3 ">
        <form onSubmit={onFormSubmit}>
          <div className="space-y-12 pb-8">
            <div className="">
              <CustomHeading text="Bot Settings" />
              <p className="text-sm leading-6 text-gray-600">
                Change your bot settings
              </p>

              <div className="mt-5 grid  gap-x-6 gap-y-8 sm:grid-cols-2 xs:grid-cols-1">
                {/* Maximum Credits Spend */}
                <div>
                  <CustomLabel
                    label="Maximum Credits Spend"
                    htmlFor="max_credit_spend_on_chatgpt"
                    labelIcon={
                      <InfoOutlinedIcon
                        fontSize="small"
                        titleAccess="Maximum Credits to be Spend on a bot."
                        className="mx-2"
                      />
                    }
                  />
                  <CustomTextInput
                    id="max_credit_spend_on_chatgpt"
                    name="max_credit_spend_on_chatgpt"
                    value={formData?.max_credit_spend_on_chatgpt}
                    inputClassNames="mt-2"
                    onChange={handleInputChange}
                  />
                </div>

                {/* Maximum message user ask limit */}
                <div>
                  <CustomLabel
                    label="Maximum message user ask limit"
                    htmlFor="max_message_user_ask_limit"
                    labelIcon={
                      <InfoOutlinedIcon
                        fontSize="small"
                        titleAccess="Maximum message user can send to bot."
                        className="mx-2"
                      />
                    }
                  />
                  <CustomTextInput
                    id="max_message_user_ask_limit"
                    name="max_message_user_ask_limit"
                    value={formData?.max_message_user_ask_limit}
                    inputClassNames="mt-2"
                    onChange={handleInputChange}
                  />
                </div>

                {/* Interval */}
                <div>
                  <CustomLabel
                    label="Interval"
                    htmlFor="interval"
                    labelIcon={
                      <InfoOutlinedIcon
                        fontSize="small"
                        titleAccess="Set your interval."
                        className="mx-2"
                      />
                    }
                  />

                  <CustomTextInput
                    id="interval"
                    name="interval"
                    value={formData?.interval}
                    inputClassNames="mt-2"
                    onChange={handleInputChange}
                  />
                </div>

                {/* No. of request in interval */}
                <div>
                  <CustomLabel
                    label="No. of request in interval"
                    htmlFor="no_of_request_in_interval"
                    labelIcon={
                      <InfoOutlinedIcon
                        fontSize="small"
                        titleAccess="Number of messages to be sent in an interval."
                        className="mx-2"
                      />
                    }
                  />
                  <CustomTextInput
                    id="no_of_request_in_interval"
                    name="no_of_request_in_interval"
                    value={formData?.no_of_request_in_interval}
                    inputClassNames="mt-2"
                    onChange={handleInputChange}
                  />
                </div>

                {/* Credit low email trigger */}
                <div>
                  <CustomLabel
                    label=" Credit low email trigger"
                    htmlFor="credit_low_email_trigger"
                    labelIcon={
                      <InfoOutlinedIcon
                        fontSize="small"
                        titleAccess="A email will be sent to you if you are getting low on credits."
                        className="mx-2"
                      />
                    }
                  />
                  <CustomSelectInput
                    id="credit_low_email_trigger"
                    name="credit_low_email_trigger"
                    value={formData?.credit_low_email_trigger}
                    inputClassNames="mt-2"
                    placeholder="Write suggested message"
                    onChange={(e) =>
                      handleSelectChange(
                        "credit_low_email_trigger",
                        e.target.value
                      )
                    }
                    options={[
                      { id: 1, value: true, label: "True" },
                      { id: 2, value: false, label: "False" },
                    ]}
                  />
                </div>

                {/* Enable Memorization */}
                <div>
                  <label
                    htmlFor="is_memory"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Enable Memorization
                    <InfoOutlinedIcon
                      fontSize="small"
                      titleAccess="Bot will memorize your previous messages."
                      className="mx-2"
                    />
                  </label>
                  <div className="mt-2">
                    <Switch
                      name="is_memory"
                      checked={formData.is_memory}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* Platform Restriction */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Platform Restriction
                    <InfoOutlinedIcon
                      fontSize="small"
                      titleAccess="Platform Restriction"
                      className="mx-2"
                    />
                    <Switch
                      name="platform_restriction"
                      checked={formData.platform_restriction}
                      onChange={handleInputChange}
                    />
                  </label>
                  <div className="mt-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="platform_mobile"
                          checked={formData.platform_mobile}
                          onChange={handleInputChange}
                          disabled={!formData.platform_restriction}
                        />
                      }
                      label="Mobile"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="platform_desktop"
                          checked={formData.platform_desktop}
                          onChange={handleInputChange}
                          disabled={!formData.platform_restriction}
                        />
                      }
                      label="Desktop"
                    />
                  </div>
                </div>

                {/* Domain Restriction */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Domain Restriction
                    <InfoOutlinedIcon
                      fontSize="small"
                      titleAccess="The widget will appear only on following domains/URLs."
                      className="mx-2"
                    />
                    <Switch
                      name="domain_restriction"
                      checked={formData.domain_restriction}
                      onChange={handleInputChange}
                      classes={{ disabled: classes.disabledSwitch }}
                    />
                  </label>
                  <CustomTextInput
                    placeholder="wwww.xyz.com, www.abc.com ..."
                    name="domain_list"
                    value={formData.domain_list}
                    onChange={handleInputChange}
                    disabled={!formData.domain_restriction}
                    inputClassNames="mt-2"
                  />
                </div>

                {/* Enable Prompt */}
                <div>
                  <label
                    htmlFor="is_prompt"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Enable Prompt
                    <InfoOutlinedIcon
                      fontSize="small"
                      titleAccess="Enter Custom Prompt"
                      className="mx-2"
                    />
                    <Switch
                      name="is_prompt"
                      checked={formData.is_prompt}
                      onChange={handleInputChange}
                    />
                  </label>
                  <CustomTextInput
                    name="custom_prompt"
                    value={formData.custom_prompt}
                    onChange={handleInputChange}
                    disabled={!formData.is_prompt}
                    inputClassNames="mt-2"
                    placeholder="Enter your custom prompt"
                  />
                </div>

                {/* Country Restriction */}
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Country Restriction
                    <InfoOutlinedIcon
                      fontSize="small"
                      titleAccess="Country Restriction"
                      className="mx-2"
                    />
                    <Switch
                      name="country_restriction"
                      checked={formData.country_restriction}
                      onChange={handleInputChange}
                    />
                  </label>
                  <div className="mt-2">
                    <FormControl
                      sx={{
                        width: "100%",
                        background: "#f9f9f9",
                      }}
                    >
                      <InputLabel
                        style={{ top: "-7px" }}
                        id="demo-multiple-chip-label"
                      >
                        Countries
                      </InputLabel>
                      <Select
                        className="mui_select"
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={formData.country_list}
                        onChange={handleChangeCountries}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        disabled={!formData.country_restriction}
                      >
                        {countries?.map((country) => (
                          <MenuItem key={country?.code} value={country?.name}>
                            {country?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-start gap-x-6">
            <CustomButton title="Save" type="submit" />

            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
          </div>
        </form>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            opacity: 0.4,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default BotSettings;
