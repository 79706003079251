import React from "react";
import { useParams } from "react-router-dom";
import WidgetSettings from "../Settings/WidgetSettings";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <Button
        onClick={() => navigate(`/conversations`)}
        size="small"
        component="label"
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        style={{ color: "black", borderColor: "black", marginRight: "15px" }}
      >
        Back
      </Button>
      <WidgetSettings from="conversations" session={params?.sessionId} />
    </div>
  );
};

export default Chat;
