import React, { useState, useRef } from "react";
import Badge from "@mui/material/Badge";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import profile_icon from "../../../../assets/images/profile_icon.svg";

const CustomizeMessageRowMessagesRow = ({
  widgetAppereance,
  setWidgetAppearance,
}) => {
  const [visitorMsgBackgroundColor, setVisitorMsgBackgroundColor] =
    useState("");
  const [botMsgBackgroundColor, setBotMsgBackgroundColor] = useState("");
  const [aiFontColor, setAiFontColor] = useState("");
  const [visitorFontColor, setVisitorFontColor] = useState("");
  const [suggMsgBackgroundColor, setSuggMsgBackgroundColor] = useState("");
  const [timeTextColor, setTimeTextColor] = useState("");

  const visitorMsgColorPickerRef = useRef(null);
  const botMsgColorPickerRef = useRef(null);
  const suggMsgColorPickerRef = useRef(null);
  const timeTextColorPickerRef = useRef(null);
  const aiFontColorolorPickerRef = useRef(null);
  const visitorFontColorolorPickerRef = useRef(null);

  const openColorPicker = (msg_type) => {
    switch (msg_type) {
      case "visitor_msg":
        visitorMsgColorPickerRef.current.click();
        break;
      case "bot_msg":
        botMsgColorPickerRef.current.click();
        break;
      case "time_text_color":
        timeTextColorPickerRef.current.click();
        break;
      case "ai_font_color":
        aiFontColorolorPickerRef.current.click();
        break;
      case "visitor_font_color":
        visitorFontColorolorPickerRef.current.click();
        break;
      case "sugg_msg":
        suggMsgColorPickerRef.current.click();
        break;
      default:
    }
  };

  const handleColorChange = (type, e) => {
    switch (type) {
      case "visitor_msg":
        setVisitorMsgBackgroundColor(e.target.value);
        setWidgetAppearance({
          ...widgetAppereance,
          visitor_msg_background_color: e.target.value,
        });
        break;
      case "bot_msg":
        setBotMsgBackgroundColor(e.target.value);
        setWidgetAppearance({
          ...widgetAppereance,
          ai_msg_background_color: e.target.value,
        });
        break;
      case "time_text_color":
        setTimeTextColor(e.target.value);
        setWidgetAppearance({
          ...widgetAppereance,
          timeTextColor: e.target.value,
        });
        break;
      case "ai_font_color":
        setAiFontColor(e.target.value);
        setWidgetAppearance({
          ...widgetAppereance,
          ai_font_color: e.target.value,
        });
        break;
      case "visitor_font_color":
        setVisitorFontColor(e.target.value);
        setWidgetAppearance({
          ...widgetAppereance,
          font_color: e.target.value,
        });
        break;
      case "sugg_msg":
        setSuggMsgBackgroundColor(e.target.value);
        setWidgetAppearance({
          ...widgetAppereance,
          suggestedMsgBackgroundColor: e.target.value,
        });
        break;
      // Add more cases as needed based on your requirements
      default:
      // Default case if type doesn't match any defined cases
    }
  };
  return (
    <>
      {/* Bot message */}
      <div className={`flex flex-col ${"items-start"} text-sm`}>
        <Badge
          className="m-badge max-w-[70%]"
          badgeContent={
            <div className="flex items-center justify-center">
              <EditOutlinedIcon
                onClick={() => openColorPicker("bot_msg")}
                style={{ fontSize: "14px" }}
              />
              <input
                type="color"
                value={botMsgBackgroundColor}
                onChange={(e) => handleColorChange("bot_msg", e)}
                ref={botMsgColorPickerRef}
                style={{ visibility: "hidden", width: 0, height: 0 }}
              />
            </div>
          }
          color="primary"
          size="small"
        >
          <div className={`flex flex-col ${"items-start"} w-[100%]`}>
            <div className="visitor_msg items-start flex flex-col w-[100%] relative">
              <div className="msg_time flex items-center justify-center ml-3 text-sm	mb-2">
                <p style={{ color: widgetAppereance?.timeTextColor }}>
                  Today, 05:30 pm
                </p>
              </div>

              <div className="flex w-[100%] justify-center items-center">
                <span
                  className="rounded  p-3 w-[100%] text-sm break-words	mr-2"
                  style={{
                    backgroundColor: widgetAppereance.ai_msg_background_color,
                    // backgroundColor: "#000C60",
                    color: widgetAppereance.ai_font_color,
                  }}
                >
                  {widgetAppereance?.initial_message ||
                    "Hi, how can I help you?"}
                </span>
                <Badge
                  className="m-badge max-w-[70%]"
                  badgeContent={
                    <div className="flex items-center justify-center">
                      <EditOutlinedIcon
                        onClick={() => openColorPicker("ai_font_color")}
                        style={{ fontSize: "14px" }}
                      />
                      <input
                        type="color"
                        value={aiFontColor}
                        onChange={(e) => handleColorChange("ai_font_color", e)}
                        ref={aiFontColorolorPickerRef}
                        style={{ visibility: "hidden", width: 0, height: 0 }}
                      />
                    </div>
                  }
                  color="primary"
                  size="small"
                ></Badge>
              </div>
              <div
                className="triangle-reverse"
                style={{
                  backgroundColor: widgetAppereance.ai_msg_background_color,
                }}
              ></div>
            </div>
            <img className="mt-6 ml-3" src={profile_icon} alt="profile_icon" />
            <br />
          </div>
        </Badge>

        <br />
      </div>

      {/* Visitor Message */}

      <div className={`flex flex-col ${"items-end"} text-sm grow`}>
        <Badge
          className="m-badge max-w-[70%]"
          badgeContent={
            <div className="flex items-center justify-center">
              <EditOutlinedIcon
                onClick={() => openColorPicker("visitor_msg")}
                style={{ fontSize: "14px" }}
              />
              <input
                type="color"
                value={visitorMsgBackgroundColor}
                onChange={(e) => handleColorChange("visitor_msg", e)}
                ref={visitorMsgColorPickerRef}
                style={{ visibility: "hidden", width: 0, height: 0 }}
              />
            </div>
          }
          color="primary"
          size="small"
        >
          <div className={`flex flex-col ${"items-end"} `}>
            <div className="bot_msg items-end flex flex-col  relative">
              <div className="msg_time flex items-center justify-center ml-3 text-sm	mt-2">
                <p style={{ color: widgetAppereance?.timeTextColor }}>
                  Today, 05:30 pm
                </p>
              </div>

              <div className="flex w-[100%] justify-center items-center">
                <Badge
                  className="m-badge max-w-[70%]"
                  badgeContent={
                    <div className="flex items-center justify-center">
                      <EditOutlinedIcon
                        onClick={() => openColorPicker("visitor_font_color")}
                        style={{ fontSize: "14px" }}
                      />
                      <input
                        type="color"
                        value={visitorFontColor}
                        onChange={(e) =>
                          handleColorChange("visitor_font_color", e)
                        }
                        ref={visitorFontColorolorPickerRef}
                        style={{ visibility: "hidden", width: 0, height: 0 }}
                      />
                    </div>
                  }
                  color="primary"
                  size="small"
                ></Badge>
                <p
                  className="rounded  text-white p-3 text-sm  w-[100%] break-words	"
                  style={{
                    backgroundColor:
                      widgetAppereance.visitor_msg_background_color,
                    // backgroundColor: "#F2F2F4",
                    color: widgetAppereance.font_color,
                  }}
                >
                  <span> Great to chat, can you tell me more about...</span>
                </p>
              </div>

              <div
                className="triangle"
                style={{
                  backgroundColor:
                    widgetAppereance.visitor_msg_background_color,
                  // backgroundColor: "#F2F2F4",
                }}
              ></div>
            </div>

            <br />
          </div>
        </Badge>
        <br />
      </div>

      {/* Suggested message */}
      {widgetAppereance?.initial_suggested_prompt?.map((item, index) => (
        <div className={`flex flex-col ${"items-start"} text-sm`} key={index}>
          <div className="visitor_msg items-start flex flex-col">
            <p
              className="rounded-md  p-2 w-[100%] "
              style={{
                border: "0.5px solid #707070",
              }}
            >
              {/* {widgetAppereance?.initial_suggested_prompt} */}
              {item}
            </p>
          </div>

          <br />
        </div>
      ))}
    </>
  );
};

export default CustomizeMessageRowMessagesRow;
