import React from "react";

const CustomLabel = ({ labelClassNames = "", htmlFor, label, labelIcon }) => {
  return (
    <>
      <label
        htmlFor={htmlFor}
        className={`block text-sm font-medium leading-6 text-gray-900 text-start ${labelClassNames}`}
      >
        {label}
        {labelIcon}
      </label>
    </>
  );
};

export default CustomLabel;
