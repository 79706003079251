import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes/routes";
import PrivateRoute from "./routes/PrivateRoute";
import { SnackbarProvider } from "notistack";

const App = () => {
  return (
    <Fragment>
      <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
        <BrowserRouter>
          <Routes>
            {routes?.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={
                  route.private ? (
                    <PrivateRoute>{route.element}</PrivateRoute>
                  ) : (
                    route.element
                  )
                }
              />
            ))}
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </Fragment>
  );
};

export default React.memo(App);
