import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { generateCodeSnippet, USDollar } from "../../utils/helper";
import { useSnackbar } from "notistack";
import { IFRAME_URL } from "../../constants/URLS";
import { getBot, getUser } from "../../common/APIs/index";
import CustomHeading from "../../components/CustomHeading";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Slider from "@mui/material/Slider";
import CustomButton from "../../components/CustomButton";
import { STRIPE_PUBLISHABLE_KEY } from "../../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import { api } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBotsByProjectId,
  setBotId,
  setProjectId,
} from "../../pages/Auth/store/authSlice";
import LoaderComponent from "../../components/LoaderComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // boxShadow: 24,
  outline: "none",
  p: 3,
  borderRadius: "5px",
};
const SideBar = ({ active, toggleSidebar, remaining_percent, tokens }) => {
  const [formData, setFormData] = useState({
    tokens: "",
  });
  const [bot, setBot] = useState();
  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { enqueueSnackbar } = useSnackbar();

  const menuItems = [
    // { id: 1, key: "menu", label: "Menu", heading: true },
    {
      id: 3,
      key: "/analytics",
      label: "Analytics and Integration",
      heading: false,
      icon: <EqualizerOutlinedIcon />,
    },
    // { id: 4, key: "/settings", label: "Settings", heading: true },
    {
      id: 5,
      key: "/widget-settings",
      label: "Customize your chatbot",
      heading: false,
      icon: <DisplaySettingsIcon />,
    },

    {
      id: 6,
      key: "/chatbot-modal",
      label: "Share your chatbot",
      heading: false,
      icon: <ShareOutlinedIcon />,
    },
    {
      id: 7,
      key: "/bots",
      label: "My Bots",
      heading: false,
      icon: <SmartToyOutlinedIcon />,
    },
    {
      id: 8,
      key: "/knowledgebase",
      label: "Knowledgebase",
      heading: false,
      icon: <PsychologyAltOutlinedIcon />,
    },

    {
      id: 9,
      key: "/playground",
      label: "Playground",
      heading: false,
      icon: <AutoStoriesOutlinedIcon />,
    },
    {
      id: 10,
      key: "/learning",
      label: "Teach your model",
      heading: false,
      icon: <PsychologyAltOutlinedIcon />,
    },
    {
      id: 11,
      key: "/conversations",
      label: "Conversations",
      heading: false,
      icon: <AutoStoriesOutlinedIcon />,
    },
    {
      id: 12,
      key: "/bot-settings",
      label: "Bot Settings",
      heading: false,
      icon: <SettingsSuggestOutlinedIcon />,
    },
  ];

  const handleClickItem = async (item) => {
    if (item?.key === "/chatbot-modal") {
      setLoading(true);
      const botSetting = await fetchBot();
      setBotSettings(botSetting);
      handleOpen();
      setLoading(false);

      return;
    }
    navigate(item.key);
  };

  const [open, setOpen] = React.useState(false);
  const [creditsModal, setCreditsModal] = React.useState(false);
  const [botsByProject, setBotsByProject] = useState({});
  const [botSettings, setBotSettings] = useState();
  const [user, setUser] = React.useState();
  const [domain, setDomain] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCreditModalOpen = () => setCreditsModal(true);
  const handleCreditModalClose = () => setCreditsModal(false);

  const [type, setType] = React.useState("script");

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const getCode = (type) => {
    let bot_id = state?.bot_id;
    let project_id = state?.project_id;
    if (type === "script") {
      const code = generateCodeSnippet({
        project_id: project_id,
        bot_id: bot_id,
        user_id: user?.id,
      });
      return code;
    } else if (type === "iframe") {
      let custom_id = {
        project_id: project_id,
        bot_id: bot_id,
      };

      let iframe_code = `<iframe src="${IFRAME_URL}?project_id=${custom_id?.project_id}&bot_id=${custom_id?.bot_id}" title="Chat Bot Iframe"></iframe>`;
      return iframe_code;
    }
  };

  const handleCopy = async () => {
    let code = await getCode(type);
    await navigator.clipboard.writeText(code);
    enqueueSnackbar("Copied to clipboard!", {
      variant: "success",
    });
  };

  const getData = async () => {
    const [user, bot] = await Promise.all([getUser(), getBot(state?.bot_id)]);
    setUser(user);
    setBot(bot);
  };

  useEffect(() => {
    getData();
  }, [state?.bot_id]);

  const marks = [
    {
      value: 5000,
      label: "5K",
    },
    {
      value: 20000,
      label: "20K",
    },
    {
      value: 40000,
      label: "40K",
    },
    {
      value: 60000,
      label: "60K",
    },
    {
      value: 80000,
      label: "80K",
    },
    {
      value: 100000,
      label: "100K",
    },
  ];

  const handleCreditsUpdate = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const calculatePrice = () => {
    let tokens = formData.tokens;
    let model_base_price = bot?.aimodel[0]?.base_price_per_token;
    const price = tokens * model_base_price;
    return price;
  };

  const makePayment = async () => {
    const postData = {
      product_id: "price_1OUSeIGKQA4JEOXgN6lQWkUa",
      purchase_token: formData?.tokens,
    };
    const stripePromise = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    let res = await api.post(`/billing/create-checkout-session`, postData);
    return stripePromise.redirectToCheckout({
      sessionId: res?.data?.sessionId,
    });
  };

  const fetchBotsByProject = async (projectId) => {
    try {
      const response = await api.get(`bots/projects/${projectId}`);
      return response?.data;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const fetchAllBots = async () => {
      const projectBots = {};

      // Loop through all projects and fetch bots for each
      for (const pro of state?.allProjects || []) {
        const bots = await fetchBotsByProject(pro?.id);
        projectBots[pro?.id] = bots;
      }
      setBotsByProject(projectBots);
    };

    fetchAllBots();
  }, [state?.allProjects]);

  const fetchBot = async () => {
    try {
      const res = await getBot(state?.bot_id);
      return res;
    } catch (error) {
      console.log("Err");
    }
  };

  const domainSubmit = async () => {
    try {
      let response = await api.post(`bots/settings/${botSettings?.id}`, {
        domain_list: [domain],
      });
      setBotSettings(response?.data);
    } catch (error) {
      console.log("Err in updating bot domain");
    }
  };

  return (
    <div>
      <div
        className={`min-h-[100%] flex flex-col sidebar    antialiased  text-gray-800    rounded-lg ${
          active ? " sidebar-active flex" : "sidebar-hidden hidden"
        }`}
        // style={{
        //   transition: "all .5s ease",
        //   transform: active ? "translateX(0)" : "translateX(-100%)",
        // }}
      >
        <div
          className="fixed flex flex-col top-0 sidebar-inner left-0 w-full bg-white   rounded-lg shadow-lg h-full"
          // style={{
          //   boxShadow:
          //     "0px 0px .9310142993927002px 0px rgba(0, 0, 0, .17), 0px 0px 3.1270833015441895px 0px rgba(0, 0, 0, .08), 0px 7px 14px 0px rgba(0, 0, 0, .05)",
          // }}
        >
          {/* <FormControl fullWidth style={{ padding: "10px" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state?.project_id}
              onChange={(e) => {
                if (e.target.value === "add") {
                  navigate(`/signup-steps`);
                } else {
                  dispatch(setProjectId(e.target.value));
                  dispatch(fetchBotsByProjectId(e.target.value));
                }
              }}
            >
              {state?.allProjects?.map((item) => (
                <MenuItem value={item?.id}>{item?.project_name}</MenuItem>
              ))}

              <MenuItem value="add">Add Project</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ padding: "10px" }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state?.bot_id}
              onChange={(e) => {
                dispatch(setBotId(e.target.value));
              }}
            >
              {state?.allBots?.map((item) => (
                <MenuItem value={item?.id}>{item?.bot_name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl fullWidth style={{ padding: "10px" }}>
            <Select
              native
              value={state?.bot_id}
              id="grouped-native-select"
              onChange={(e) => {
                const selectedValue = e.target.value;
                const selectedCategory =
                  e.target.options[e.target.selectedIndex].getAttribute(
                    "data-category"
                  );

                if (selectedValue === "add-project") {
                  navigate(`/signup-steps`);
                } else {
                  dispatch(setProjectId(selectedCategory));
                  dispatch(
                    fetchBotsByProjectId({
                      projectId: selectedCategory,
                      botId: selectedValue,
                    })
                  );
                  dispatch(setBotId(selectedValue));
                }
              }}
            >
              {state?.allProjects?.map((pro) => (
                <optgroup label={pro?.project_name} key={pro?.id}>
                  {botsByProject[pro?.id]?.map((bot) => (
                    <option
                      key={bot?.id}
                      value={bot?.id}
                      data-category={pro?.id}
                    >
                      {bot?.bot_name}
                    </option>
                  ))}
                </optgroup>
              ))}

              <optgroup label="New Project" key="add-pro">
                <option
                  key="add-project"
                  value="add-project"
                  data-category="add-project"
                >
                  + Add new Project
                </option>
              </optgroup>
            </Select>
          </FormControl>

          <div className="overflow-y-auto overflow-x-hidden flex-grow">
            <ul className="flex flex-col py-4 space-y-1">
              {menuItems?.map((menu) => (
                <li
                  key={menu.id}
                  className={menu.heading ? "px-5" : ""}
                  onClick={() => {
                    handleClickItem(menu);
                  }}
                >
                  {menu.heading ? (
                    <div className="flex flex-row items-center h-8">
                      <div
                        className="text-sm font-light tracking-wide text-gray-500"
                        title={menu.label}
                      >
                        {menu.label}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`cursor-pointer relative flex flex-row items-center h-11 focus:outline-none text-lg text-gray-600 hover:text-white-800 border-l-4 border-transparent hover:border-indigo-500  pr-2 ${
                        pathname === menu?.key
                          ? "border-l-4 !border-indigo-500 bg-gray-50 gradient-background"
                          : ""
                      }`}
                      title={menu.label}
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        {menu.icon}
                      </span>
                      <span className="ml-2 text-sm tracking-wide truncate">
                        {menu.label}
                      </span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex pl-3 pr-1 pb-4 pt-3 center-div">
            <div className="total_credits whitespace-nowrap">
              {remaining_percent} %
            </div>
            <div className="flex flex-col text-xs ml-2 grow">
              <p className="font-bold heading-color text-lg">
                {tokens?.total_purchased_tokens - tokens?.total_used_tokens}
              </p>
              <div className=" flex items-center">
                <p>Credits remaining</p>

                <ArrowUpwardIcon fontSize="small" className="ml-2" />
                <span
                  className="gradient-color ml-1 font-bold cursor-pointer"
                  onClick={handleCreditModalOpen}
                >
                  Credits
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`absolute top-5 left-5 cursor-pointer hidden menu-item-toggle ${
          active ? "active " : "active"
        }`}
        onClick={toggleSidebar}
      >
        <svg
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
      </div>

      {/* Share your bot modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <CustomHeading text="Share you chatbot via" />
              <CloseIcon className="cursor-pointer" onClick={handleClose} />
            </div>
            <br />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value="iframe">Iframe Code</MenuItem>
                <MenuItem value="script">Script Code</MenuItem>
              </Select>
            </FormControl>
            {(botSettings?.domain_list === null ||
              botSettings?.domain_list?.every(
                (item) => item.trim() === ""
              )) && (
              <>
                <TextField
                  className="!top-1 !my-5 w-[100%] mui_txt_inp"
                  id="outlined-basic"
                  label="Your Domain"
                  variant="outlined"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                />
                <CustomButton title="Save" onClick={domainSubmit} />
              </>
            )}
            {type === "iframe" &&
              botSettings?.domain_list?.every((item) => item.trim() !== "") && (
                <div className="w-full mt-5 ">
                  <div className="flex  items-end justify-end cursor-pointer">
                    <p className="text-sm" onClick={handleCopy}>
                      Copy
                    </p>
                    <ContentCopyIcon
                      fontSize="small"
                      className=" ml-1"
                      onClick={handleCopy}
                    />
                  </div>

                  <TextField
                    className="w-full !mt-2"
                    id="outlined-multiline-flexible"
                    label="Iframe "
                    multiline
                    disabled={true}
                    value={getCode("iframe")}
                  />
                </div>
              )}
            {type === "script" && botSettings?.domain_list?.length >= 1 && (
              <div className="w-full mt-5 ">
                <div className="flex  items-end justify-end cursor-pointer">
                  <p className="text-sm" onClick={handleCopy}>
                    Copy
                  </p>
                  <ContentCopyIcon
                    fontSize="small"
                    className=" ml-1"
                    onClick={handleCopy}
                  />
                </div>

                <TextField
                  className="w-full !mt-2"
                  id="outlined-multiline-flexible"
                  label="Code Snippet"
                  multiline
                  maxRows={4}
                  disabled={true}
                  value={getCode("script")}
                />
              </div>
            )}
          </Box>
        </Fade>
      </Modal>

      {/* Credits Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={creditsModal}
        onClose={handleCreditModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={creditsModal}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <CustomHeading text="Total Credits" />
              <CloseIcon
                className="cursor-pointer"
                onClick={handleCreditModalClose}
              />
            </div>
            <br />
            <h2>
              Your remaing credits:{" "}
              <strong>
                {" "}
                {tokens?.total_purchased_tokens - tokens?.total_used_tokens ??
                  "0"}
              </strong>
            </h2>
            <div className="w-[100%] mt-4">
              <Slider
                aria-label="Custom marks"
                defaultValue={5000}
                onChange={handleCreditsUpdate}
                min={5000}
                name="tokens"
                max={100000}
                valueLabelDisplay="auto"
                marks={marks}
              />
            </div>

            <div className="text-end mt-5">
              Total tokens: {formData.tokens}{" "}
              <strong className="mr-3">
                {USDollar.format(calculatePrice())}
              </strong>{" "}
              <CustomButton title="Proceed" onClick={makePayment} />
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Loader */}
      <LoaderComponent loading={loading} />
    </div>
  );
};

export default SideBar;
