import axios from "axios";
import { clearLocalStorage, getItemFromLocalStorage } from "./localStorage";
import { CHATBOT_MODELLING_API_URL, FRONT_END_URL } from "../constants/URLS";

export const conv_api = axios.create({
  baseURL: CHATBOT_MODELLING_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

// Request interceptor for API calls
conv_api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("access_token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
conv_api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.code === "ERR_NETWORK") {
      // originalRequest._retry = true;
      // const access_token = await refreshAccessToken();
      // axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      // return api(originalRequest);
      clearLocalStorage();
      window.location.href = FRONT_END_URL;
    }
    return Promise.reject(error);
  }
);
