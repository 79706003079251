import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import user_avatar from "../../../../assets/images/user_avatar.png";

import "./index.css";
const WidgetHeader = ({ widgetAppereance, setWidgetAppearance }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (widgetAppereance?.profile_pic) {
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(widgetAppereance?.profile_pic);
    }
  }, [widgetAppereance]);
  const [imgColor, setImgColor] = useState("#000000"); // Initial color is black
  const [closeIconColor, setCloseIconColor] = useState("#000000"); // Initial color is black

  const imgColorPickerRef = useRef(null);
  const closeIconColorPickerRef = useRef(null);

  const handleImgColorChange = (event) => {
    setImgColor(event.target.value);
  };
  const handleCloseIconColorChange = (event) => {
    setCloseIconColor(event.target.value);
  };

  const openImageColorPicker = () => {
    // Open the color picker by focusing on it
    imgColorPickerRef.current.click();
  };

  const openCloseIconColorPicker = () => {
    // Open the color picker by focusing on it
    closeIconColorPickerRef.current.click();
  };

  return (
    <>
      <div
        className="flex justify-between p-4 items-center gradient-background  rounded-t-lg"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 25px",
        }}
      >
        <div>
          {/* <Badge
            badgeContent={
              <div className="flex items-center justify-center">
                <EditOutlinedIcon
                  onClick={openImageColorPicker}
                  style={{ fontSize: "14px" }}
                />
                <input
                  type="color"
                  value={imgColor}
                  onChange={handleImgColorChange}
                  ref={imgColorPickerRef}
                  style={{ visibility: "hidden", width: 0, height: 0 }}
                />
              </div>
            }
            // style={{ padding: "0 !important", borderRadius: "50%" }}
            // className="!p-0 rounded-2xl"
            color="primary"
            size="small"
          >
            <Avatar alt="Cindy Baker" src={selectedImage} />
          </Badge> */}

          <Avatar
            className="bg-white p-0.5"
            src={
              widgetAppereance?.client_logo_url &&
              widgetAppereance?.client_logo_url !== ""
                ? widgetAppereance?.client_logo_url
                : user_avatar
            }
          />
        </div>
        <div>
          <p className="text-md font-semibold text-white">Chat with us</p>
        </div>
        <div>
          {/* <Badge
            badgeContent={
              <div className="flex items-center justify-center">
                <EditOutlinedIcon
                  onClick={openCloseIconColorPicker}
                  style={{ fontSize: "14px" }}
                />
                <input
                  type="color"
                  value={closeIconColor}
                  onChange={handleCloseIconColorChange}
                  ref={closeIconColorPickerRef}
                  style={{ visibility: "hidden", width: 0, height: 0 }}
                />
              </div>
            }
            // style={{ padding: "0 !important", borderRadius: "50%" }}
            // className="!p-0 rounded-2xl"
            color="primary"
            size="small"
          >
            <CloseIcon />
          </Badge> */}

          {/* <CloseIcon /> */}
        </div>
      </div>
    </>
  );
};

export default WidgetHeader;
