import axios from "axios";
import { CHATBOT_CORE_API_URL, FRONT_END_URL } from "../constants/URLS";
import { clearLocalStorage } from "../../src/services/localStorage";
import Cookies from "js-cookie";
export const api = axios.create({
  baseURL: CHATBOT_CORE_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("access_token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.code === "ERR_NETWORK") {
      clearLocalStorage();
      Cookies.remove("allProjects");
      Cookies.remove("allBots");
      Cookies.remove("project_id");
      Cookies.remove("bot_id");
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      Cookies.remove("user");
      window.location.href = FRONT_END_URL;
    }
    return Promise.reject(error);
  }
);
