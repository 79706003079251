import React from "react";

const CustomHeading = (props) => {
  const { text } = props;

  const words = text.split(" ");
  const lastWord = words[words?.length - 1];
  const remainingWords = words?.slice(0, -1);

  return (
    <>
      <h2 className="heading-color !text-3xl !font-semibold	">
        {remainingWords?.join(" ")}{" "}
        <span className="gradient-color ">{lastWord}</span>
      </h2>
    </>
  );
};

export default CustomHeading;
