import * as React from "react";
import { layoutRoutes } from "../routes/routes";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "./menuItems";
import SideBar from "./SideBar";
import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./index.css";
import axios from "axios";
import { CHATBOT_CORE_API_URL } from "../constants/URLS";
import { api } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken, setRefreshToken } from "../pages/Auth/store/authSlice";

export default function AppLayout() {
  const state = useSelector((state) => state.auth);
  const disptach = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const location = useLocation();
  const [tokens, setTokens] = useState();
  const [remaining_percent, setRemainingPercent] = useState();

  const navigateToRoute = (key) => {
    navigate(`/${key}`);
  };
  const toggleSidebar = () => {
    setActive(!active);
  };

  let accessTokenExpirationTimeout;
  let expiresIn = 5;

  const refreshToken = async () => {
    try {
      const refreshToken = state?.refresh_token;

      const response = await axios.post(
        `${CHATBOT_CORE_API_URL}users/refresh`,
        {
          refresh_token: refreshToken,
        }
      );
      const { access_token, refresh_token } = response.data;

      disptach(setAccessToken(access_token));
      disptach(setRefreshToken(refresh_token));

      scheduleTokenRefresh(expiresIn - 1);
    } catch (error) {
      console.error("Error refreshing access token:", error);
      // navigate(location.pathname);
    }
  };

  const scheduleTokenRefresh = (timeInMinutes) => {
    if (accessTokenExpirationTimeout) {
      clearTimeout(accessTokenExpirationTimeout);
    }

    accessTokenExpirationTimeout = setTimeout(() => {
      refreshToken();
    }, timeInMinutes * 60 * 1000);
  };

  const initializeTokenRefresh = (expiresInMinutes) => {
    scheduleTokenRefresh(expiresInMinutes - 1);
  };

  useEffect(() => {
    initializeTokenRefresh(expiresIn);
    return () => {
      clearTimeout(accessTokenExpirationTimeout);
    };
  }, []);

  const getCreditsCount = async () => {
    try {
      let response = await api.get("dashboard/get_used_tokens");
      let tokens = response?.data;
      setTokens(tokens);
      const remainingPercentage =
        ((tokens?.total_purchased_tokens - tokens?.total_used_tokens) /
          tokens?.total_purchased_tokens) *
        100;
      const roundedRemainingPercentage = Math.round(remainingPercentage);
      setRemainingPercent(roundedRemainingPercentage);
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    getCreditsCount();
  }, [location.pathname]);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setActive(window.innerWidth <= 768 ? false : true);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div>
        <Navbar />

        <div className="layout_content">
          <br></br>
          <SideBar
            active={active}
            toggleSidebar={toggleSidebar}
            remaining_percent={remaining_percent}
            tokens={tokens}
          />
          <div className="layout_route">
            <Routes>
              {layoutRoutes?.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
