import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../Layout/Navbar";
import { api } from "../../../services/api";
import { USDollar } from "../../../utils/helper";
import { updateBot } from "./APIs/botApi";
import { storeInLocalStorage } from "../../../services/localStorage";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import FormHelperText from "@mui/material/FormHelperText";
import { useSnackbar } from "notistack";
import { loadStripe } from "@stripe/stripe-js";
import "./index.css";
import { STRIPE_PUBLISHABLE_KEY } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import {
  fetchBotsByProjectId,
  setAllProjects,
  setBotId,
  setProjectId,
} from "../store/authSlice";
import LoaderComponent from "../../../components/LoaderComponent";
import { getProject, getUserProjects } from "../../../common/APIs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
  cardOption: {
    position: "relative",
    width: "100%",
    cursor: "pointer",
    padding: "10px 0px",
    border: "1px solid #e0e0e0",
    borderRadius: 5,
    margin: "0px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s",
    boxShadow: "none !important",
    "&:hover": {
      transform: "scale(1.01)",
      transition: "transform",
      boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
    },
  },
}));
const SignupSteps = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const stripePromise = loadStripe(
    "pk_test_51HEunVGKQA4JEOXghSndhRD5U3dBhkHhIcrQb7m5hHwsZu3NH7flxoOS9OlnDgTizcl36SE25TnhfThtKfsIUjL100TNDSpc3l"
  );
  const [isActiveCard, setIsActiveCard] = useState("front-card");
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [modalPrice, setModalPrice] = useState();
  const [industries, setIndustries] = useState();
  const [aiModels, setAiModels] = useState();
  const [validationError, setValidationError] = useState({
    project_name: "",
    industry_id: "",
    bot_name: "",
  });

  const [formData, setFormData] = useState({
    project_name: "",
    bot_name: "",
    project_id: "",
    industry_id: "",
    aimodel_id: "",
    tokens: "",
  });

  const marks = [
    {
      value: 5000,
      label: "5K",
    },
    {
      value: 20000,
      label: "20K",
    },
    {
      value: 40000,
      label: "40K",
    },
    {
      value: 60000,
      label: "60K",
    },
    {
      value: 80000,
      label: "80K",
    },
    {
      value: 100000,
      label: "100K",
    },
  ];

  const createProject = async (data) => {
    try {
      let response = await api.post(`projects/`, data);
      return response?.data;
    } catch (error) {
      // console.log("Error", error?.response?.data?.detail);
      if (error?.response?.data?.detail?.includes(["duplicate"])) {
        enqueueSnackbar("Project with this name already exists!", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(error?.response?.data?.detail, { variant: "error" });
      }
    }
  };

  const createBot = async (data) => {
    try {
      let response = await api.post(`bots/`, data);
      return response?.data;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.detail, { variant: "error" });
    }
  };

  const makePayment = async () => {
    const postData = {
      product_id: "price_1OUSeIGKQA4JEOXgN6lQWkUa",
      purchase_token: formData?.tokens - 5000,
    };
    const stripePromise = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    let res = await api.post(`/billing/create-checkout-session`, postData);
    return stripePromise.redirectToCheckout({
      sessionId: res?.data?.sessionId,
    });
  };
  const flipCard = async (e, back = false) => {
    setLoading(true);
    // Validate form fields
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).some((key) => validationErrors[key])) {
      // If there are validation errors, update the state and prevent form submission
      setValidationError(validationErrors);
      return;
    }
    // Clear any previous validation errors
    setValidationError({});
    e.preventDefault();
    if (back) {
      setIsActiveCard(
        isActiveCard === "back-card" ? "front-card" : "back-card"
      );
      return;
    }
    let project = {
      project_name: formData?.project_name,
      project_desc: `${formData?.project_name} Description`,
    };
    let projectResponse = await createProject(project);
    if (projectResponse) {
      dispatch(setProjectId(projectResponse?.id));
      storeInLocalStorage("project_id", projectResponse?.id);
      let botValues = {
        ...formData,
        project_id: projectResponse?.id,
        custom_prompt: "Custom Prompt",
      };
      let botResponse = await createBot(botValues);
      const allProjects = await getUserProjects();
      dispatch(setAllProjects(allProjects));
      dispatch(setBotId(botResponse?.id));
      storeInLocalStorage("bot_id", botResponse?.id);
      dispatch(
        fetchBotsByProjectId({
          projectId: projectResponse?.id,
          botId: botResponse?.id,
        })
      );
      if (formData?.tokens > 5000) {
        makePayment();
        return;
      }
      navigate("/widget-settings");
      setLoading(false);

      setIsActiveCard(
        isActiveCard === "back-card" ? "front-card" : "back-card"
      );
    }
  };

  const validateForm = () => {
    // Define validation rules for each field
    const validationErrors = {
      project_name:
        formData?.project_name.trim() === "" ? "Project name is required" : "",
      industry_id:
        formData?.industry_id.trim() === "" ? "Industry  is required" : "",
      aimodel_id:
        formData?.aimodel_id.trim() === "" ? "Model  is required" : "",
    };

    return validationErrors;
  };

  const handleType = (selectedModel) => {
    setFormData({ ...formData, aimodel_id: selectedModel?.id });
    setModalPrice(selectedModel?.base_price_per_token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   let botValues = {
  //     id: botId,
  //     bot_name: formData?.bot_name,
  //   };
  //   let botResponse = await updateBot(botValues);
  //   if (botResponse) {
  //     navigate("/analytics");
  //   }
  // };

  const fetchData = async () => {
    const getModelsAndIndustries = async (endpoint) => {
      let response = await api.get(`${endpoint}/`);
      return response?.data;
    };

    const apiEndpoints = ["industries", "aimodels"];

    const promises = await Promise.all(
      apiEndpoints?.map((endpoint) => getModelsAndIndustries(endpoint))
    );
    return promises;
  };
  useEffect(() => {
    const getData = async () => {
      let data = await fetchData();
      setIndustries(data[0]);
      setAiModels(data[1]);
    };
    getData();
  }, []);

  const getIndustryName = (industryId) => {
    const industry = industries?.find((i) => i.id === industryId);
    return industry ? industry.industry_name : "Unknown Industry";
  };

  const getModelName = (modelId) => {
    const model = aiModels?.find((m) => m.id === modelId);
    return model ? model.aimodel_desc : "Unknown Model";
  };

  const keyMappings = {
    project_name: "Project Name",
    bot_name: "Bot Name",
    industry_id: "Industry",
    aimodel_id: "Ai Model",
    tokens: "Tokens",
  };

  const calculatePrice = () => {
    let tokens = formData.tokens - 5000;
    return tokens * modalPrice;
  };

  return (
    <>
      <div className="flex items-center justify-center w-[100%] fixed ">
        <Navbar showHeaderRight={false} />
      </div>

      <div className="w-[100%] flex flex-col justify-center items-center h-[100vh]">
        {/* FIRST CARD */}
        <div
          className={` border-solid rounded-md  p-10 min-h-[450px] min-w-[300px] max-w-[60%] w-[100%] flip-animate ${
            isActiveCard === "front-card" ? "front-card" : "hidden"
          }`}
        >
          <h2 className="font-semibold	text-center text-3xl  heading-color">
            Create your AI <span className="gradient-color">Bot</span>
          </h2>
          <p className="text-center mt-2 mb-5">Personalize your experience.</p>
          <div className="flex justify-center items-center flex-col border border-slate-300 rounded p-2 bg-white px-6 py-8">
            <TextField
              fullWidth
              className="mui-text-field"
              id="outlined-basic"
              name="bot_name"
              value={formData.bot_name}
              onChange={handleChange}
              label="Name your chatbot"
              variant="outlined"
              error={!!validationError.bot_name}
              helperText={validationError.bot_name}
            />
            <div className="flex mt-8 w-[100%]">
              <TextField
                className="mui-text-field"
                fullWidth
                id="outlined-basic"
                name="project_name"
                value={formData.project_name}
                onChange={handleChange}
                label="Name your project"
                variant="outlined"
                error={!!validationError.project_name}
                helperText={validationError.project_name}
              />
              <FormControl className="!ml-3 mui-text-field" fullWidth>
                <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                <Select
                  className="mui_select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.industry_id}
                  label="Industry"
                  name="industry_id"
                  onChange={handleChange}
                  error={!!validationError.industry_id}
                >
                  {industries?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.industry_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "#D32E2F" }}>
                  {validationError.industry_id}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <br />
          <div className="flex">
            <CardContent className="w-[100%] flex justify-center items-center">
              <Grid
                container
                spacing={2}
                className=" min-w-[80%] flex !w-[80%]"
              >
                {aiModels?.map((item) => (
                  <Grid key={item.id} item xs={12} sm={4} className=" ">
                    <Card
                      className={`${classes.cardOption} ${
                        formData?.aimodel_id === item.id ? classes.selected : ""
                      }`}
                      onClick={() => handleType(item)}
                    >
                      <div
                        className={`absolute top-2 right-2 ${
                          formData?.aimodel_id === item.id
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        <CheckCircleSharpIcon
                          color="success"
                          fontSize="small"
                        />
                      </div>
                      {item.aimodel_name}
                    </Card>
                  </Grid>
                ))}
                <FormHelperText style={{ color: "#D32E2F" }}>
                  {validationError.aimodel_id}
                </FormHelperText>
              </Grid>
            </CardContent>
          </div>
          <br />
          <div className="flex">
            <div className="w-[100%]">
              {/* <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                No. of tokens
              </label> */}
              <Slider
                aria-label="Custom marks"
                defaultValue={5000}
                onChange={handleChange}
                min={5000}
                name="tokens"
                max={100000}
                valueLabelDisplay="auto"
                marks={marks}
                disabled={formData?.aimodel_id === ""}
              />
            </div>
          </div>
          <br /> <br />
          <div className="flex items-center justify-end">
            {formData?.aimodel_id !== "" && (
              <p className="mr-3">
                {formData?.aimodel_id === "" || formData?.tokens <= 5000 ? (
                  `${
                    formData?.tokens === "" ? "5000" : formData?.tokens
                  } Free Tokens`
                ) : (
                  <> {USDollar.format(calculatePrice())}</>
                )}
              </p>
            )}

            <div className="justify-start flex mt-3 items-center mt-5">
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate(`/`)}
                className="!ml-3 empty_btn"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                className="!ml-3 prim_btn"
                size="small"
                onClick={flipCard}
              >
                {formData?.tokens > 5000 ? "Proceed to payment" : "Next"}
              </Button>
            </div>
          </div>
        </div>

        {/* SECOND CARD */}

        {/* <div
          style={{
            transition: "all .5s ease",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          className={`min-h-450 border-solid rounded-md flex-col p-10 min-h-[450px] min-w-[300px] max-w-[60%] w-[100%]  flex justify-center items-center back-card ${
            isActiveCard === "back-card" ? "back-card" : "hidden"
          }`}
        >
          <div className="w-[100%] grid  gap-x-6 gap-y-8 sm:grid-cols-2 xs:grid-cols-1">
            {Object.entries(formData)
              ?.filter(
                ([key, value]) => key !== "project_id" && key !== "bot_name"
              )
              ?.map(([key, value]) => (
                <div key={key}>
                  <label className="block text-sm font-bold leading-6 text-gray-900">
                    {keyMappings[key] || key}
                  </label>
                  <div className="mt-2">
                    {key === "industry_id"
                      ? getIndustryName(value)
                      : key === "aimodel_id"
                      ? getModelName(value)
                      : key === "tokens" && value === ""
                      ? "5000"
                      : value}
                  </div>
                </div>
              ))}
          </div>
          <br />
          <br />
          <TextField
            id="outlined-basic"
            label="Name your Bot"
            variant="outlined"
            className="min-w-[200px] max-w-[400px] w-[100%]"
            name="bot_name"
            value={formData.bot_name}
            onChange={handleChange}
          />
          <br />
          <div className="flex ">
            {/* <Button variant="text" onClick={(e) => flipCard(e, true)}>
              Back
            </Button> 

            <Button
              className="!ml-5"
              variant="contained"
              onClick={handleSubmit}
            >
              Finish
            </Button>
          </div>
        </div> */}
      </div>

      <LoaderComponent loading={loading} />
    </>
  );
};

export default SignupSteps;
