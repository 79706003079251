import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { api } from "../../services/api";
import { getDate } from "../../utils/helper";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [bots, setBots] = React.useState([]);
  const [botsLoading, setBotLoading] = React.useState(false);

  const getBots = async (project) => {
    setBotLoading(true);
    try {
      let response = await api.get(`bots/projects/${project?.id}`);
      setBots(response?.data);
    } catch (error) {
      console.log("Err", error);
    } finally {
      setBotLoading(false);
    }
  };
  React.useEffect(() => {
    if (open) getBots(row);
  }, [open]);
  //   if (botsLoading) return <div>Loading...</div>;
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.project_name}
        </TableCell>
        <TableCell>{getDate(row.created_at)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Bots
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className="!font-bold">Bot Name</TableCell>
                    <TableCell className="!font-bold">Created At</TableCell>
                    <TableCell className="!font-bold">Enabled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bots?.map((bot) => (
                    <TableRow key={bot.id}>
                      <TableCell component="th" scope="row">
                        {bot?.bot_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getDate(bot.created_at)}
                      </TableCell>
                      <TableCell>{bot.enabled.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Projects() {
  const [projects, setProjects] = React.useState([]);

  const getProjects = async () => {
    try {
      let response = await api.get(`projects/`);
      setProjects(response?.data);
    } catch (error) {
      console.log("Err", error);
    }
  };

  React.useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <h2 className="text-lg font-medium">My Projects</h2>
      <br></br>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "0" }} />
              <TableCell className="!font-bold">Project Name</TableCell>
              <TableCell className="!font-bold">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects?.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
