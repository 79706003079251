import React from "react";
import { Button } from "@mui/material";
import "./index.css";

const CustomButton = (props) => {
  const { onClick = () => {}, title, className, loading = true, type } = props;
  return (
    <>
      <Button
        variant="contained"
        className={`custom_btn ${className}`}
        size="small"
        onClick={onClick}
        onLoad={loading}
        type={type}
      >
        {title}
      </Button>
    </>
  );
};

export default CustomButton;
