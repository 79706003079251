import React from "react";

const CustomTextInput = ({
  type = "text",
  value,
  onChange,
  placeholder,
  id,
  inputClassNames = "",
  name,
  defautlValue,
  icon,
  disabled = false,
  onFocus = () => {},
  hidden = false,
}) => {
  return (
    <>
      {icon ? (
        <div className="flex flex-1 items-center">
          <img src={icon} />
          <input
            disabled={disabled}
            value={value}
            type="text"
            placeholder={placeholder}
            id={id}
            name={name}
            onChange={onChange}
            defaultValue={defautlValue}
            onFocus={onFocus}
            className="w-[100%] ms-2"
            hidden={hidden}
          />
        </div>
      ) : (
        <input
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          className={` w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${inputClassNames}`}
          defaultValue={defautlValue}
          onFocus={onFocus}
          hidden={hidden}
        />
      )}
    </>
  );
};

export default CustomTextInput;
