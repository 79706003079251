import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const state = useSelector((state) => state?.auth);
  const authed = state?.access_token || false;
  const location = useLocation();

  if (!authed) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
