import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getDate, getFormValues } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import CustomHeading from "../../components/CustomHeading";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import AddNewBot from "./AddNewBot";
import { api } from "../../services/api";
import { useSnackbar } from "notistack";
import { fetchBotsByProjectId } from "../Auth/store/authSlice";
import DeleteModal from "../../components/DeleteModal";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export default function Bots() {
  const { enqueueSnackbar } = useSnackbar();
  const disptach = useDispatch();
  const state = useSelector((state) => state?.auth);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelModal, setDelModal] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const [selectedDoc, setSelectedDoc] = useState();

  const handleOpenDelete = () => setDelModal(true);
  const handleCloseDelete = () => setDelModal(false);

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    try {
      setLoading(true);
      let data = {
        ...formData,
        project_id: state?.project_id,
      };
      const response = await api.post("bots/", data);
      enqueueSnackbar("Successfully created Bot!", {
        variant: "success",
      });
      disptach(
        fetchBotsByProjectId({
          projectId: state?.project_id,
          botId: state?.bot_id,
        })
      );
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.detail || "Not created. Something went wrong!",
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      let response = await api.delete(`bots/${selectedDoc?.id}`);
      enqueueSnackbar("Document deleted successfully!", {
        variant: "success",
      });
      handleCloseDelete();
    } catch (error) {
      enqueueSnackbar("Document not deleted", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };
  const handleClose = async () => {
    setOpen(false);
  };
  return (
    <>
      <div className="flex justify-between items-center">
        <CustomHeading text="My Bots" />
        <Button
          onClick={handleOpenModal}
          size="large"
          component="label"
          variant="outlined"
          startIcon={<AddIcon />}
          style={{ color: "black", borderColor: "black", marginLeft: "5px" }}
        >
          Add new bot
        </Button>
      </div>
      <br></br>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ background: "var(--gradient-color)" }}>
            <TableRow>
              <TableCell className="!text-white">Bot Name</TableCell>
              <TableCell className="!text-white">Created At</TableCell>
              {/* <TableCell className="!text-white">Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.allBots?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.bot_name}</TableCell>
                <TableCell> {getDate(row.created_at)}</TableCell>
                {/* <TableCell>
                  <DeleteOutlinedIcon
                    className="cursor-pointer"
                    aria-label="delete"
                    onClick={() => {
                      setSelectedDoc(row);
                      handleOpenDelete();
                    }}
                  />
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddNewBot
        open={open}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        loading={loading}
        setLoading={setLoading}
      />

      {openDelModal && (
        <DeleteModal
          loading={loading}
          open={openDelModal}
          handleCloseDelete={handleCloseDelete}
          handleDelete={handleDelete}
          title="Are you sure you want to delete this Bot? "
        />
      )}
    </>
  );
}
