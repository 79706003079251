import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import feature_req from "../../assets/images/feature_req.png";
import feedback from "../../assets/images/feedback.png";
import "./index.css";
import CustomButton from "../CustomButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  width: "80%",
  minWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #D3D3D3",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  outline: "none",
};

export default function FeedbackModal({ open, handleClose }) {
  const [formValues, setFormValues] = React.useState({
    type: "",
    feedback: "",
  });

  const handleSubmit = async () => {
    try {
      console.log("Values", formValues);
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Give us a Feedback
              </Typography>
              <CloseIcon onClick={handleClose} className="cursor-pointer" />
            </div>
            <p className="text-[#64748B]">
              Tell us how we could make the product more useful for you.
            </p>

            <div className="mt-5">
              <p className="font-semibold text-sm">Select Type</p>
              <div className="mt-3 flex items-center input-div">
                <img
                  src={feature_req}
                  alt="feature_req"
                  width={18}
                  height={33}
                />

                <select
                  value={formValues.type}
                  onChange={handleChange}
                  name="type"
                  required
                  placeholder="Select Type  "
                  className="ms-1  block w-full shadow-none focus:outline-none rounded-md border-0 py-1 px-2 text-gray-900   placeholder:text-gray-400  sm:text-sm sm:leading-6"
                >
                  <option value="feature_request">Feature Request</option>
                  <option value="bug_report">Bug Report</option>
                </select>
              </div>
            </div>

            <div className="mt-3">
              <p className="font-semibold text-sm">Feedback</p>
              <div className="mt-3 flex items-center input-div">
                <img src={feedback} alt="feature_req" width={18} height={33} />
                <textarea
                  value={formValues.feedback}
                  onChange={handleChange}
                  name="feedback"
                  required
                  rows={1}
                  placeholder="Feedback"
                  className="ms-1  block w-full rounded-md border-0 py-1 px-2 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="center-div">
              <CustomButton
                loading={true}
                title="Submit"
                className="!mt-5"
                onClick={handleSubmit}
              />
            </div>
            <p className="text-xs text-center mt-5">
              Powered by <strong className="underline">Botnest</strong>
            </p>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
