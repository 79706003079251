import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getFormValues } from "../../../utils/helper";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import { CHATBOT_CORE_API_URL } from "../../../constants/URLS";
import CustomHeading from "../../../components/CustomHeading";
import Navbar from "../../../Layout/Navbar";
import CustomLabel from "../../../components/CustomLabel";
import CustomTextInput from "../../../components/CustomTextInput";
import CustomButton from "../../../components/CustomButton";
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("reset_token");
    if (token) {
      setResetToken(token);
    }
  }, [location]);

  const navigate = useNavigate();

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    const values = getFormValues(event);

    if (values.new_password !== values.confirm_password) {
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${CHATBOT_CORE_API_URL}users/reset-password`,
        values
      );
      enqueueSnackbar(response?.data?.detail, { variant: "success" });
      navigate("/login");
    } catch (error) {
      console.log("Error", error?.response?.data?.detail);
      enqueueSnackbar(`${error?.response?.data?.detail}`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-[100vh] flex flex-col justify-start items-center">
      <Navbar showHeaderRight={false} />

      <div className="flex w-[100%] items-center justify-center h-[100%]">
        <div className=" bg-white flex max-w-[80%] sm:max-w-[60%] md:max-w-[60%]  lg:max-w-[30%%] xl:max-w-[30%] flex-col justify-center px-6 py-8 lg:px-8 text-center border border-slate-300 rounded">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <CustomHeading text="Reset your password" />
            <p className="text-center break-words text-sm mt-2 decoration-slate-400	">
              Join us today & experience the power of AI-powered text creation for yourself!
            </p>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmitForm}>
              <CustomLabel
                label="New Password"
                htmlFor="new_password"
                labelClassNames="text-start"
              />
              <CustomTextInput
                id="new_password"
                name="new_password"
                type="password"
                required
                placeholder="Enter your new password"
                inputClassNames="!mt-1"
              />

              <CustomLabel
                label="Confirm Password"
                htmlFor="confirm_password"
                labelClassNames="text-start"
              />
              <CustomTextInput
                id="confirm_password"
                name="confirm_password"
                type="password"
                required
                placeholder="Enter your new password again"
                inputClassNames="!mt-1"
              />

              <CustomTextInput
                name="reset_token"
                type="text"
                placeholder="Enter youe new password"
                inputClassNames="!mt-1 !none"
                value={resetToken}
                hidden={true}
              />

              <div className="flex justify-center item-center">
                <CustomButton title="Submit" type="submit" />
              </div>
            </form>

            <Divider className="!my-4">or</Divider>

            <div
              className="py-4 text-sm"
              style={{ backgroundColor: "#FFEBEB" }}
            >
              Go back to login{" "}
              <span className="underline">
                <Link
                  to="/login"
                  className="cursor-pointer font-semibold leading-6 "
                >
                  Sign In
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ResetPassword;
